body.fedex {
    @media (max-width: 490px) {
        #rightMenu {
            width: 100vw !important;
        }

        .slideOutShowMenu {
            left: -100vw !important;
        }

        .header-spacer {
            height: 0 !important;
        }

        .web-only {
            display: none !important;
        }

        .close-icon {
            background-color: var(--primary-highlight);
            width: 45px;
            height: 45px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    @media (min-width: 491px) {
        .mobile-only {
            display: none !important;
        }
    }

    @media (min-width: 992px) {
        .copyright {
            display: flex;
        }
    }

    #rightMenu {
        display: block;
        transition: .5s;
        overflow-x: hidden;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 240px;
    }

    #mainBody {
        transition: .5s;
        position: relative;
        top: 0;
        right: 0;
        z-index: 2;
        background-color: white;
        min-height: calc(100vh - 100px);

    }

    .slideOutShowMenu {
        left: -280px;
    }

    .slideInHideMenu {
        left: 0;
    }
}