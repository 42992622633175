<div class="modal-container">
  <div class="modal-body">
    <div class="container-fluid">
      <div class="title">
      Please Login or Signup
      </div>
      <div class="mb-2"> 
 Please log in with your FedEx Account or create a User ID to ensure a
 seamless experience moving forward with your custom box.
      </div>
      <div class="returning">
Returning Custom Branded Box customers will need to create a new user ID or log in
with an existing FedEx account. Please <a [href]="supportEmail">email</a> or call <a [href]="">{{supportPhone}}</a> for support with
 information on your previous orders.
      </div>
      <div class="button-div">
        <form name="register" ngNoForm [action]="loginUrl" method="post">
          <button type="submit" class="orange-outline">
            CREATE A USER ID
          </button>
          <input name="form_key" type="hidden" [value]="formKey">
          <input name="action_type" type="hidden" value="register">
          <input name="product_config_data" type="hidden" [value]="productConfigData">
          <input name="configuration_data" type="hidden" [value]="configurationData">
        </form>
        <form name="login" ngNoForm [action]="loginUrl" method="post">
          <button type="submit" class="orange-submit">
            LOG IN
          </button>
          <input name="form_key" type="hidden" [value]="formKey">
          <input name="action_type" type="hidden" value="login">
          <input name="product_config_data" type="hidden" [value]="productConfigData">
          <input name="configuration_data" type="hidden" [value]="configurationData">
        </form>
      </div>
    </div>
  </div>
</div>
